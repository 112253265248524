import { useState, useRef } from 'react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { PRODUCT_TYPES } from '@constants';
import { Chevron, Flex, VerticalCard } from '@components';
import { Heading } from '@components';
import { NormalizedVariant } from '@ts/product';
import 'swiper/css';
import { useBaseFrameProducts } from '@services/shopify';
import styles from './RecommendationCarousel.module.scss';

type RecommendationCarouselProps = {
	variant: NormalizedVariant;
	selectedFrame: string;
	headingText?: string;
	productTypes?: (typeof PRODUCT_TYPES)[keyof typeof PRODUCT_TYPES][];
	demo?: string;
	dataTags?: Record<
		'button' | 'zoom' | 'favorite',
		{
			[key: string]: string | boolean;
		}
	>;
};

/**
 * This is the same as the RecommendationCarousel component, but it always shows the same recommendations –– a list of Base Frames.
 * This is used on the Base Frame PDP since Algolia rarely recommends more Base Frame products for a given Base Frame.
 */
const FakeRecommendationCarousel = ({ variant, headingText, dataTags }: RecommendationCarouselProps) => {
	const [isBeginning, setIsBeginning] = useState(false);
	const [isEnd, setIsEnd] = useState(false);

	const navigationPrevRef = useRef(null);
	const navigationNextRef = useRef(null);

	const navigationRefs = {
		prevEl: navigationPrevRef.current,
		nextEl: navigationNextRef.current,
	};

	const { data: recommendations } = useBaseFrameProducts();

	const mappedRecommendations =
		recommendations &&
		variant &&
		recommendations.map(product => {
			if (!product || product.name === 'PAIRCare') return;

			const selectedVariant = product.variants[0];

			if (!selectedVariant?.availableForSale) return;

			// currently-featured Base Frames shouldn't recommend themselves
			if (product.type.includes(PRODUCT_TYPES.BASE_FRAME) && product.name === variant.name) {
				return null;
			}

			return (
				<SwiperSlide key={`recommendation-${product.id}-${product.name}`} className={styles.collectionSlider}>
					<VerticalCard
						primaryAction='view'
						product={product}
						showVariantControls
						variant={selectedVariant}
						label={`Explore ${product.name}`}
						secondaryAction='none'
						dataTags={dataTags}
					/>
				</SwiperSlide>
			);
		});

	return (
		<div className={styles.container} data-recommendation-carousel={variant?.type}>
			<Heading tag='h4' className={styles.heading}>
				{headingText}
			</Heading>
			<div className={styles.collectionTrack}>
				<Swiper
					autoplay={false}
					direction='horizontal'
					modules={[Navigation]}
					navigation={navigationRefs}
					onSlidesGridLengthChange={swiper => {
						setIsBeginning(swiper.isBeginning);
						setIsEnd(swiper.isEnd);
						swiper.on('reachEnd', () => {
							setIsEnd(true);
						});
					}}
					onSlideChange={swiper => {
						setIsBeginning(swiper.isBeginning);
						setIsEnd(swiper.isEnd);
					}}
					slidesPerView='auto'
					spaceBetween={16}
				>
					<ul>{recommendations ? mappedRecommendations : null}</ul>
					<Flex fullWidth justify='end' gap={3} className={styles['navigation-container']}>
						<div className={`${styles.prevButton} ${isBeginning ? styles.disabled : ''}`} ref={navigationPrevRef}>
							<Chevron direction='left' />
						</div>
						<div className={`${styles.nextButton} ${isEnd ? styles.disabled : ''}`} ref={navigationNextRef}>
							<Chevron direction='right' />
						</div>
					</Flex>
				</Swiper>
			</div>
		</div>
	);
};

export default FakeRecommendationCarousel;
